<template>
    <v-container fluid pr-4 pl-4>
        <div class="filters-presa">
            <v-row>
                <v-col sm='12' class="gestione-filters">
                    <div class="text-right pb-4 pr-3" @click="goRouterBack">
                        <i class="far fa-times-circle close"/>
                    </div>
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left">Presa</th>
                            <th class="text-left">Operatore</th>
                            <th class="text-left">Completate</th>
                            <th class="text-left">Associazione</th>
                            <th class="text-left">Cliente</th>
                            <th class="text-left">Consegna</th>
                            <th class="text-center" style="width: 60px;"></th>
                            <th class="text-center" style="width: 60px;"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style="border-bottom: unset">
                                <v-menu v-model="calendarDataPresa" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="viewDataPresa" prepend-icon="mdi-calendar" readonly v-on="on"/>
                                    </template>
                                    <v-date-picker v-model="dataPresa" @input="changeDataPresa"/>
                                </v-menu>
                            </td>
                            <td style="border-bottom: unset">
                                <v-select outlined
                                          v-model="selectedOperatorePresa"
                                          :items="operatoriPresa"
                                          item-text="rName"
                                          item-value="_id"
                                          @change="addToUrl('op', selectedOperatorePresa)"/>
                            </td>
                            <td style="border-bottom: unset">
                                <v-select outlined
                                          v-model="selectedStatoPesa"
                                          :items="statiPresa"
                                          item-text="name"
                                          item-value="value"
                                          @change="addToUrl('stato', selectedStatoPesa)"/>
                            </td>
                            <td style="border-bottom: unset">
                                <v-select outlined
                                          v-model="selectedOperatoreAssociato"
                                          :items="operatoriPresa"
                                          item-text="rName"
                                          item-value="_id"
                                          @change="addToUrl('oa', selectedOperatoreAssociato)"/>
                            </td>
                            <td style="border-bottom: unset" class="search-client">
                                <v-autocomplete label="Cerca per nome, cognome o email"
                                                cache-items flat
                                                hide-no-data
                                                return-object
                                                clearable
                                                solo-inverted
                                                prepend-icon="mdi-magnify"
                                                :search-input.sync="searchUser"
                                                :items="listUsers"
                                                item-text="rNameSearchUser"
                                                v-model="selectedUser"
                                                @change="addToUrl('user', selectedUser && selectedUser._id ? selectedUser._id : '')" />
                            </td>
                            <td style="border-bottom: unset">
                                <v-menu v-model="calendarDataConsegna" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="viewDataConsegna" prepend-icon="mdi-calendar" readonly v-on="on"/>
                                    </template>
                                    <v-date-picker v-model="dataConsegna" @input="changeDataConsegna"/>
                                </v-menu>
                            </td>
                            <td class="text-center" style="width: 70px; border-bottom: unset;" @click="resetFilter">
                                <i style="color: #222;" class="fas fa-sync"></i>
                            </td>
                            <td  class="text-center" style="width: 70px; border-bottom: unset;" @click="modalEsportazione = true">
                                <i style="color: #222;" class="fas fa-download"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <label>Numero presa</label>
                                <v-text-field v-model="searchNumber" @input="initSearchPresa"/>
                            </td>
                            <td>
                                <label>Bici officina</label>
                                <v-checkbox v-model="checkOfficina" @change="addToUrl('officina', checkOfficina)"></v-checkbox>
                            </td>
                            <td>
                                <label>Bici negozio</label>
                                <v-checkbox v-model="checkNegozio" @change="addToUrl('negozio', checkNegozio)"></v-checkbox>
                            </td>
                            <td>
                                <label>Bici online</label>
                                <v-checkbox v-model="checkOnline" @change="addToUrl('online', checkOnline)"></v-checkbox>
                            </td>
                            <td>
                                <label>Noleggio</label>
                                <v-checkbox v-model="checkNoleggio" @change="addToUrl('noleggio', checkNoleggio)"></v-checkbox>
                            </td>
                            <td>
                                <label>Esposizione</label>
                                <v-checkbox v-model="checkEsposizione" @change="addToUrl('esposizione', checkEsposizione)"></v-checkbox>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-col>
            </v-row>
            <template v-if="!loadingPrese">
                <v-simple-table class="content-table table-lavorazioni">
                    <thead>
                    <tr>
                        <th class="text-left" />
                        <th class="text-left">Ordine</th>
                        <th class="text-left">Cliente</th>
                        <th class="text-left">Bici</th>
                        <th class="text-left">Presa</th>
                        <th class="text-left">Dt. Lavoro</th>
                        <th class="text-left">Consegna</th>
                        <th class="text-left">Associato</th>
                        <th class="text-left"/>
                        <th class="text-left"/>
                        <th class="text-left"/>
                        <th class="text-left"/>
                        <th class="text-center" data-tooltip title="Lavaggio"><i class="fas fa-shower"></i></th>
                        <th class="text-center" data-tooltip title="Fatta"><i class="fas fa-check"></i></th>
                        <th class="text-center" data-tooltip title="Assegnata"><i class="fas fa-user-friends"></i></th>
                        <th class="text-center" data-tooltip title="Pagata"><i class="fas fa-dollar-sign"></i></th>
                        <!--th class="text-center" data-tooltip title="consegnata"><i class="fas fa-shipping-fast"></i></th-->
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="presa in listPrese" :key="presa._id">
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})">
                            <b># {{ presa.numero }}</b>
                            <br>
                            <small v-if="presa.tipo === 0 || !presa.tipo">Bici Officina</small>
                            <small v-else-if="presa.tipo === 1">Bici Negozio</small>
                            <small v-else-if="presa.tipo === 2">Bici Online</small>
                            <small v-else-if="presa.tipo === 3">Noleggio / Test</small>
                            <small v-else-if="presa.tipo === 4">Esposizione</small>
                        </td>
                        <td>
                        <span v-if="presa.id_ordine && presa.ordine && presa.ordine[0] && presa.ordine[0].numero">
                           <span v-if="presa.ordine[0].spedizione_premium">
                                <i class="fas fa-crown" style="color: #c29436"></i>
                           </span>
                            <span v-if="presa.ordine[0].dettagli_ordine && presa.ordine[0].dettagli_ordine.montaggio_prodotti" class="ml-2" data-tooltip title="Montaggio premium">
                                <i class="fas fa-puzzle-piece"></i>
                            </span>
                            # {{ presa.ordine[0].numero }}
                        </span>
                        </td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})"><b>{{ presa.rNameUser }}</b></td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})"><b>{{ presa.rNameBike }}</b></td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})">{{ presa.viewDataPresa }}</td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})">{{ presa.viewDataLavoro }}</td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})">{{ presa.viewDataConsegna }}</td>
                        <td @click="$router.push({ path: '/dettagli', query: { _id: presa._id }})">{{ presa.rNameOperatore }}</td>
                        <td>
                            <router-link :to="{ path: '/dettagli', query: { _id: presa._id }}">
                                <i class="fas fa-tasks"></i>
                            </router-link>
                        </td>
                        <td>
                            <router-link :to="{ path: '/presa', query: { _id: presa._id }}">
                                <i class="far fa-edit"></i>
                            </router-link>
                        </td>
                        <td>
                            <i @click="eliminaPresa(presa._id)" class="fas fa-trash" style="color: #222"></i>
                        </td>
                        <td>
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <i style="color: #222" class="fas fa-print" v-on="on"/>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <a @click="apriPagina(rConfig.domainAdmin + '/admin/prese/stampa_meccanico?_id='+presa._id)"><v-list-item-title>Copia Meccanico</v-list-item-title></a>
                                    </v-list-item>
                                    <v-list-item>
                                        <a @click="apriPagina(rConfig.domainAdmin + '/admin/prese/stampa?_id='+presa._id)"><v-list-item-title>Saldo cliente</v-list-item-title></a>
                                    </v-list-item>
                                    <v-list-item v-if="presa.id_ordine">
                                        <a @click="apriPagina(rConfig.domainAdmin + '/admin/ordini/stampa?_id='+presa.id_ordine)"><v-list-item-title>Copia ordine cliente</v-list-item-title></a>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </td>
                        <td class="text-center">
                            <i v-if="presa.lavaggio" class="fas fa-shower"/>
                        </td>
                        <td class="text-center">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" disabled :checked="presa.completata">
                                <i class="far fa-circle unchecked"/>
                                <i class="fas fa-check-circle checked"/>
                            </label>
                        </td>
                        <td class="text-center associato">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" disabled :checked="presa.operatore_associato">
                                <i class="far fa-circle unchecked"/>
                                <i class="fas fa-check-circle checked"/>
                            </label>
                        </td>
                        <td class="text-center">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" disabled :checked="presa.pagamento && presa.pagamento.pagato">
                                <i class="far fa-circle unchecked"/>
                                <i class="fas fa-check-circle checked"/>
                            </label>
                        </td>
                        <!--td class="text-center">
                            <label class="fancy-checkbox in-form">
                                <input type="checkbox" disabled :checked="presa.consegnata">
                                <i class="far fa-circle unchecked"/>
                                <i class="fas fa-check-circle checked"/>
                            </label>
                        </td-->
                    </tr>
                    </tbody>
                </v-simple-table>
            </template>
            <template v-if="loadingPrese">
                <v-row class="mt-16">
                    <v-col sm="12" class="text-center">
                        <i class="fas fa-circle-notch fa-spin"></i> LOADING
                    </v-col>
                </v-row>
            </template>
            <v-row>
                <v-col sm="4"/>
                <v-col sm="4">
                    <v-pagination v-model="page" @input="addToUrl('page', page)" :length="lengthPagination" circle />
                </v-col>
                <v-col sm="4"/>
            </v-row>
        </div>
        <template>
            <div class="text-center">
                <v-dialog v-model="modalEsportazione" width="500">
                    <v-card>
                        <v-card-title class="text-h5 primary">
                            Esporta
                        </v-card-title>
                        <v-row>
                            <v-col cols="6">
                                <v-menu ref="menuDa"
                                        v-model="menuDa"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="esportaDa"
                                                      label="Da"
                                                      prepend-icon="mdi-calendar"
                                                      readonly
                                                      v-bind="attrs"
                                                      v-on="on" />
                                    </template>
                                    <v-date-picker v-model="esportaDa" @change="saveExportDa" type="month"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6">
                                <v-menu ref="menuA"
                                        v-model="menuA"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="esportaA"
                                                      label="A"
                                                      prepend-icon="mdi-calendar"
                                                      readonly
                                                      v-bind="attrs"
                                                      v-on="on" />
                                    </template>
                                    <v-date-picker v-model="esportaA" @change="saveExportA" type="month"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="esportaPrese">
                                Esporta
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </template>
    </v-container>
</template>
<script>
import { momentDate } from "@/components/js/formatDate";
import apiUser from '@/components/Presa/cliente/js/user';
import apiPresa from '@/components/Presa/js/presa';
import { generalFunction } from "@/components/js/generalFunction";
import config from "@/config/config";

export default {
    name: 'Tutti',
    props: ['nonAssegnati'],
    data() {
        return {
            page: 1,
            pageSize: 20,
            searchUser: undefined,
            searchNumber: undefined,
            selectedUser: undefined,
            loadingSearchUser: false,
            loadingPrese: false,
            listUsers: [],
            lengthPagination: 0,
            checkOfficina: this.controlReload('officina'),
            checkNegozio: this.controlReload('negozio'),
            checkOnline: this.controlReload('online'),
            checkNoleggio: this.controlReload('noleggio'),
            checkEsposizione: this.controlReload('esposizione'),
            operatoriPresa: [],
            selectedOperatorePresa: this.controlReload('op'),
            selectedOperatoreAssociato: this.controlReload('oa'),
            statiPresa: [
                {
                    name: 'Tutti',
                    value: 0,
                }, {
                    name: 'Completate',
                    value: 1
                }, {
                    name: 'Non completate',
                    value: 2
                }
            ],
            selectedStatoPesa: this.controlReload('stato'),
            calendarDataPresa: false,
            dataPresa: this.$route.query.data_presa,
            viewDataPresa: momentDate(this.$route.query.data_presa),
            calendarDataConsegna: false,
            dataConsegna: this.$route.query.data_consegna,
            viewDataConsegna: momentDate(this.$route.query.data_consegna),
            listPrese: [],
            timeourRicercaUser: undefined,
            brands: [],
            modalEsportazione: false,
            menuDa: undefined,
            menuA: undefined,
            esportaDa: undefined,
            esportaA: undefined
        }
    },
    async created() {
        let brands = [] = await generalFunction.getBrands();
        if (
            brands &&
            brands[1]
        ) {
            for (let brand of brands[1]) {
                brand.rName = 'Senza Nome';
                if (
                    brand &&
                    brand.it &&
                    brand.it.nome
                ) brand.rName = brand.it.nome;
            }
        }
        this.brands = brands[1];
        this.initSearchPresa();
        let token = await generalFunction.getToken();
        if (token) {
            let operatoriPresa = [];
            operatoriPresa.push({  _id: 0, rName: 'Tutti'});
            let operatori = await generalFunction.getOperatori(token);
            if (
                operatori &&
                operatori.length > 0
            ) {
                for (let operatore of operatori) {
                    operatore.rName = '';
                    if (operatore.nome)
                        operatore.rName += operatore.nome + " ";
                    if (operatore.cognome)
                        operatore.rName += operatore.cognome;

                    operatoriPresa.push(operatore)
                }
            }
            this.operatoriPresa = operatoriPresa;
        }
    },
    methods: {
        goRouterBack() {
            this.$router.back();
        },
        controlReload(param) {
            switch (param) {
                case 'stato': {
                    if (
                        this.$route.query[param] ||
                        this.$route.query[param] === 0
                    ) return Number(this.$route.query[param]);
                    else return 0;
                }
                case 'negozio':
                case 'online':
                case 'noleggio':
                case 'esposizione':
                case 'officina': {
                    return  (
                        this.$route.query[param] &&
                        this.$route.query[param] === 'true'
                    )
                }
                case 'oa':
                case 'op': {
                    if (
                        this.$route.query[param] &&
                        this.$route.query[param] !== '0'
                    ) return this.$route.query[param];
                    else return 0;
                }
            }
        },
        changeDataPresa() {
            this.calendarDataPresa = false;
            this.viewDataPresa = momentDate(this.dataPresa);
            this.addToUrl('data_presa', this.dataPresa);
        },
        changeDataConsegna() {
            this.calendarDataConsegna = false;
            this.viewDataConsegna = momentDate(this.dataConsegna);
            this.addToUrl('data_consegna', this.dataConsegna);
        },
        async addToUrl(params, value) {
            await this.$router.push({ path: '/gestione', query: Object.assign({}, this.$route.query,{ [params]: value } )});
            this.initSearchPresa();
        },
        async resetFilter() {
            await this.$router.push({ path: '/gestione', query: Object.assign({}, {})}).catch(() => {});
            this.initSearchPresa();
        },
        async eliminaPresa(id_presa) {
            if(confirm('Sicuro di voler eliminare questa Presa?')) {
                let token = await generalFunction.getToken();
                await apiPresa.deletePresa(token, {
                    _id: id_presa
                });
                this.initSearchPresa();
            }
        },
        async initSearchPresa() {
            this.lengthPagination = 0;
            this.listPrese = [];
            this.loadingPrese = true;

            let token = await generalFunction.getToken();
            if (token) {
                let data = {
                    page: this.page,
                    page_size: this.pageSize
                };

                if (this.$route.query.data_presa)
                    data.data_presa = this.$route.query.data_presa;

                if (this.$route.query.data_consegna)
                    data.data_consegna = this.$route.query.data_consegna;

                if (
                    this.$route.query.officina &&
                    this.$route.query.officina === 'true'
                ) data.officina = true;

                if (
                    this.$route.query.negozio &&
                    this.$route.query.negozio === 'true'
                ) data.negozio = true;

                if (
                    this.$route.query.online &&
                    this.$route.query.online === 'true'
                ) data.online = true;

                if (
                    this.$route.query.noleggio &&
                    this.$route.query.noleggio === 'true'
                ) data.noleggio = true;

                if (
                    this.$route.query.esposizione &&
                    this.$route.query.esposizione === 'true'
                ) data.esposizione = true;

                if (this.$route.query.op)
                    data.operatore_presa = this.$route.query.op;

                if (this.$route.query.stato)
                    data.stato = this.$route.query.stato;

                if (this.$route.query.oa)
                    data.operatore_associato = this.$route.query.oa;

                if (this.$route.query.user)
                    data.id_utente = this.$route.query.user;

                if (this.nonAssegnati)
                    data.non_assegnati = true;

                if (this.searchNumber)
                    data.search = this.searchNumber;

                let prese = await apiPresa.searchPrese(token, data);
                if (prese) {
                    if (prese[0]) {
                        for (let presa of prese[0]) {
                            presa.rNameUser = '';
                            presa.rNameBike = '';

                            if (presa.data_presa)
                                presa.viewDataPresa = momentDate(presa.data_presa);

                            if (presa.data_consegna)
                                presa.viewDataConsegna = momentDate(presa.data_consegna);

                            if (presa.data_lavoro)
                                presa.viewDataLavoro = momentDate(presa.data_lavoro);

                            if (
                                presa.user &&
                                presa.user[0]
                            ) {
                                if (presa.user[0].nome)
                                    presa.rNameUser += presa.user[0].nome;

                                if (presa.user[0].cognome)
                                    presa.rNameUser += ' ' + presa.user[0].cognome;

                                if (
                                    presa.id_bici &&
                                    presa.user[0].dettagli_utente &&
                                    presa.user[0].dettagli_utente.bici
                                ) {
                                    let bike = presa.user[0].dettagli_utente.bici.find(bike => {
                                        return bike._id === presa.id_bici;
                                    });

                                    if(
                                        bike &&
                                        bike['marca']
                                    ) {
                                        let rMarca = this.brands.find(brand => {
                                            return brand._id === bike['marca'];
                                        });
                                        if(rMarca)
                                            bike['rMarca'] = rMarca['rName'];
                                    }

                                    if (
                                        bike &&
                                        bike.modello
                                    ) presa.rNameBike = (bike.rMarca ? (bike.rMarca + ' ') : '') + bike.modello;
                                }
                            }

                            if (
                                presa.operatore &&
                                presa.operatore[0]
                            ) {
                                if (presa.operatore[0].nome)
                                    presa.rNameOperatore = presa.operatore[0].nome;
                                else if (presa.operatore[0].cognome)
                                    presa.rNameOperatore = presa.operatore[0].cognome;
                            }
                        }
                        this.listPrese = prese[0];
                    }
                    if (prese[1])
                        this.lengthPagination = Math.ceil(prese[1] / this.pageSize);

                    this.loadingPrese = false;
                } else this.loadingPrese = false;
            } else this.loadingPrese = false;
        },
        async startSearchUser(search) {
            if (this.loadingSearchUser) return;
            this.loadingSearchUser = true;
            let token = await generalFunction.getToken();
            if (
                search &&
                token
            ) {
                let data = '';
                data += "&token="+token;
                data += "&cerca="+search;
                let users = await apiUser.searchUsers(data);
                let arrayUsers = [];
                if (
                    users &&
                    users.length
                ) {
                    for (let user of users) {
                        user.rNameSearchUser = '';
                        if (user.nome) user.rNameSearchUser += user.nome;
                        if (user.cognome) user.rNameSearchUser += ' ' + user.cognome;
                        if (user.email) user.rNameSearchUser += ' ' + user.email;
                        if (user.telefono) user.rNameSearchUser += ' (' + user.telefono + ')';
                        arrayUsers.push(user);
                    }
                }
                this.listUsers = arrayUsers
            }
            this.loadingSearchUser = false;
        },
        apriPagina(url) {
            window.open(url)
        },
        saveExportDa(date) {
            this.$refs.menuDa.save(date)
        },
        saveExportA(date) {
            this.$refs.menuA.save(date)
        },
        async esportaPrese() {
            if (
                this.esportaDa &&
                this.esportaA
            ) {
                let token = await generalFunction.getToken();
                let pathFile = await apiPresa.esportaPrese(token, {
                    data_da: this.esportaDa,
                    data_a: this.esportaA
                });
                if (pathFile) {
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(new Blob([pathFile]));
                    link.setAttribute('download', 'EsportazionePrese.csv');
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else alert('Non è stata trovata nessuna presa.')
            } else alert('Inserisci le date!');
        }
    },
    watch: {
        nonAssegnati: function () {
            this.initSearchPresa();
        },
        async searchUser(search) {
            clearTimeout(this.timeourRicercaUser);
            let those = this;
            this.timeourRicercaUser = setTimeout(function () {
                those.startSearchUser(search);
            }, 500);
        },
    },
    computed: {
        rConfig() {
            return config;
        }
    }
}
</script>
