<template>
    <v-layout text-center wrap class="navbar">
        <v-toolbar>
            <router-link to="/">
                <v-app-bar-nav-icon v-if="!$route.meta.hideDashIcon" />
                <i class="fas fa-home" v-else />
            </router-link>
            <v-toolbar-title>
                <h4>{{ titleNavbar }} <span v-if="operatoreNavbar"><i class="fas fa-circle"  :style="{ color: rColorOperatore + '!important' }"/> {{ rNameOperatore }}</span></h4>
            </v-toolbar-title>
            <v-spacer/>
            <div class="gestisci-lavorazioni" v-if="viewFilterGestione">
                <v-tabs v-model="tab"
                        centered dark icons-and-text
                >
                    <v-tab :href="`#tab-${1}`">
                        Tutti
                    </v-tab>
                    <v-tab :href="`#tab-${2}`">
                        <v-badge color="red" style="position: absolute;right: 35px;top: 16px;padding-left: 0">
                            <template v-slot:badge>
                                <small>{{ preseNonAssegnate }}</small>
                            </template>
                        </v-badge>
                        Non assegnati
                    </v-tab>
                    <v-tab :href="`#tab-${3}`">
                        Agenda
                    </v-tab>
                </v-tabs>
            </div>
            <v-spacer/>
            <template v-if="$vuetify.breakpoint.smAndUp">
                <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                        <v-btn dark icon v-on="on">
                            <v-icon>mdi-account-circle</v-icon>
                            <span>{{ adminName }}</span>
                            <v-icon>mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-title @click="logout">Logout</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-toolbar>
    </v-layout>
</template>
<script>
import apiUser from '@/components/utenti/js/user';
import apiPrese from '@/components/Presa/js/presa';
const { generalFunction } = require('@/components/js/generalFunction.js');
import { EventBus } from "../eventBus";

export default {
    name: 'Navbar',
    props: [
        'titleNavbar',
        'operatoreNavbar'
    ],
    data() {
        return {
            adminName: undefined,
            viewFilterGestione: false,
            rNameOperatore: undefined,
            rColorOperatore: undefined,
            preseNonAssegnate: 0
        }
    },
    async created() {
        let token = await generalFunction.getToken();
        if (token) {
            let admin = await apiUser.getMyAdminUser(token);
            if (admin) {
                if (admin.nome) this.adminName = admin.nome;
                if (admin.cognome) this.adminName += ' ' + admin.cognome;
            }
            let preseNonAssegnate = await apiPrese.getNumeroNonAssegnate(token);
            if(
                preseNonAssegnate &&
                preseNonAssegnate['count']
            ) this.preseNonAssegnate = preseNonAssegnate['count'];
        }
    },
    computed: {
        tab: {
            get() {
                return this.$route.query.tab || 'tab-1';
            },
            set(newTab) {
                this.$router.push({ query: { ...this.$route.query, tab: newTab } });
                EventBus.$emit('changeTabGestione', newTab);
            }
        }
    },
    methods: {
        async logout() {
            let token = generalFunction.getToken();
            if (token) {
                await apiUser.logout(token).then(async (response) => {
                    if (response) {
                        await generalFunction.setCookie('tokenofficina', '', 0);
                        await this.$router.push({ path: '/login' });
                    } else alert('Spiacenti si è verficato un errore');
                });
            } else await this.$router.push({ path: '/login' });
        }
    },
    watch: {
        async '$route'(to) {
            this.viewFilterGestione = to.meta.gestione;
        },
        operatoreNavbar: async function () {
            this.rNameOperatore = undefined;
            this.rColorOperatore = undefined;
            let token = await generalFunction.getToken();
            if (token && this.operatoreNavbar) {
                let operatore = await apiUser.getOperatote(token, this.operatoreNavbar);
                if (operatore) {
                    if (operatore.nome) this.rNameOperatore = operatore.nome;
                    else if (operatore.cognome) this.rNameOperatore = operatore.cognome;

                    this.rColorOperatore = '#ccc';
                    if (operatore.colore) this.rColorOperatore = operatore.colore;
                }
            }
        }
    }
}
</script>
