<template>
    <div class="gestisci-lavorazioni">
        <Tutti v-if="tab === 'tab-1'" :nonAssegnati="false"/>
        <Tutti v-if="tab === 'tab-2'" :nonAssegnati="true"/>
        <Associati v-if="tab === 'tab-3'" :action="'lista'" :days="7"/>
    </div>
</template>
<script>
    import Tutti from '@/components/gestione/Tutti';
    import Associati from '@/components/gestione/associati/Associati';
    import { EventBus } from "../../eventBus";

    export default {
        name: 'GestioneLavori',
        components: { Tutti, Associati },
        data() {
            return {
                tab: this.$route.query.tab
            }
        },
        created() {
            EventBus.$emit('updateTitleNavbar', 'Gestione lavori');
            EventBus.$on('changeTabGestione', (tab) => {
                this.tab = tab;
            });
        }
    }
</script>
