<template>
    <v-layout text-center wrap>
        <v-container fluid>
            <v-row>
                <v-col xs="12" md="4">
                    <router-link to="/presa">
                        <v-card class="mx-auto card-one flex">
                            <v-btn text><v-icon>mdi-arrow-right-bold-circle</v-icon> Nuova Presa</v-btn>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col xs="12" md="4">
                    <router-link to="/gestione?tab=tab-1">
                        <v-card class="mx-auto card-one">
                            <v-btn text><v-icon>mdi-arrow-right-bold-circle</v-icon> Gestisci Lavori</v-btn>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col xs="12" md="4">
                    <router-link to="/consegna">
                        <v-card class="mx-auto card-one">
                            <v-btn text><v-icon>mdi-arrow-right-bold-circle</v-icon> In Consegna</v-btn>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col xs="12" md="4">
                    <router-link to="/garanzie">
                        <v-card class="mx-auto card-one">
                            <v-btn text><v-icon>mdi-arrow-right-bold-circle</v-icon> Garanzie</v-btn>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col xs="12" md="4" v-for="operatore in operatori" :key="operatore._id">
                    <router-link :to="{ path: '/utente', query: { _id: operatore._id }}">
                        <v-card class="mx-auto card-one" :style="{ backgroundColor: operatore.rColorOperatore + '!important' }">
                            <v-btn text><v-icon>mdi-arrow-right-bold-circle</v-icon> {{ operatore.rName }}</v-btn>
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </v-layout>
</template>
<script>
    import { generalFunction } from "@/components/js/generalFunction";
    import { EventBus } from "../eventBus";

    export default {
        name: 'Home',
        data() {
            return {
                operatori: [],
            }
        },
        async created() {
            EventBus.$emit('updateTitleNavbar');
            let token = await generalFunction.getToken();
            if (token) {
                let operatori = await generalFunction.getOperatori(token);
                if (
                    operatori &&
                    operatori.length > 0
                ) {
                    for (let operatore of operatori) {
                        operatore.rName = '';
                        if (operatore.nome)
                            operatore.rName += operatore.nome;
                        else if (operatore.cognome)
                            operatore.rName += operatore.cognome;

                        operatore.rColorOperatore = '#ccc';
                        if (operatore.colore)
                            operatore.rColorOperatore = operatore.colore;
                    }
                    this.operatori = operatori;
                }
            }
        }
    };
</script>
